import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import { GoPrimitiveDot } from 'react-icons/go'



const ValuesOne = ({image}) => {
    const { rpdata } = useContext(GlobalDataContext)


    return (
        <div
            className='py-[150px] bg-cover bg-no-repeat bg-fixed bg-center relative before:bg-zinc-900/50 before:absolute before:w-full before:h-full before:top-0'
            style={{ backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/surtido-arepas-relleno-plato_23-2148742392.avif?alt=media&token=d44edfc0-6660-4bb2-99ed-906f7e26d5eb")` }}
        >
             <img
                src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/image%20(5).png?alt=media&token=c9af34df-44bf-4595-909a-86f0c9ab5d30"}
                alt='no found'
                loading='lazy'
                className='absolute top-0 left-0 w-[100%] h-[6%]'
            />

            <img
                src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/image%20(6).png?alt=media&token=89428890-d8bb-4227-8838-dbffb470c309"}
                alt='no found'
                loading='lazy'
                className='absolute bottom-0 left-0 w-[100%] h-[6%]'
            />
            <div className='relative w-4/5 mx-auto flex justify-star'>
                <div className='bg-white w-full lg:w-1/2 border-r-[15px] borderColor px-5 py-10'>
                    {
                        rpdata?.dbValues?.map((items, index) => {
                            return (
                                <div key={index} className='pb-5'>
                                    <h4 className='flex items-center'>
                                        <GoPrimitiveDot className='dotsColor' fontSize={35} />
                                        <span>{items.title} </span>
                                    </h4>
                                    <p className='pl-9'>{items.description}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ValuesOne